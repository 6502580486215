/* tslint:disable */
/* eslint-disable */
/**
 * 维修系统
 * 维修系统:v0.0.1版本
 *
 * The version of the OpenAPI document: v0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { CustRepairTicketCreateReply } from '../models';
// @ts-ignore
import { CustRepairTicketCreateRequest } from '../models';
// @ts-ignore
import { CustRepairTicketDetailReply } from '../models';
// @ts-ignore
import { CustRepairTicketPageReply } from '../models';
// @ts-ignore
import { CustRepairTicketReplaceRequest } from '../models';
// @ts-ignore
import { Operation } from '../models';
/**
 * RepairTicketApi - axios parameter creator
 * @export
 */
export const RepairTicketApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 维修单（自营）列表
         * @param {number} [pageNo] 页码
         * @param {number} [pageSize] 页大小
         * @param {string} [q] 筛选条件
         * @param {string} [orderBy] 排序条件
         * @param {string} [fields] 展示字段
         * @param {number} [isResourceAuthorization] 是否资源鉴权
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        custapiRepairTicketGet: async (pageNo?: number, pageSize?: number, q?: string, orderBy?: string, fields?: string, isResourceAuthorization?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/custapi/RepairTicket`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (pageNo !== undefined) {
                localVarQueryParameter['PageNo'] = pageNo;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }

            if (q !== undefined) {
                localVarQueryParameter['Q'] = q;
            }

            if (orderBy !== undefined) {
                localVarQueryParameter['OrderBy'] = orderBy;
            }

            if (fields !== undefined) {
                localVarQueryParameter['Fields'] = fields;
            }

            if (isResourceAuthorization !== undefined) {
                localVarQueryParameter['IsResourceAuthorization'] = isResourceAuthorization;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 删除维修单（自营）
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        custapiRepairTicketIdDelete: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('custapiRepairTicketIdDelete', 'id', id)
            const localVarPath = `/custapi/RepairTicket/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 维修单（自营）详情
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        custapiRepairTicketIdGet: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('custapiRepairTicketIdGet', 'id', id)
            const localVarPath = `/custapi/RepairTicket/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 更新维修单（自营）
         * @param {number} id 
         * @param {CustRepairTicketReplaceRequest} [custRepairTicketReplaceRequest] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        custapiRepairTicketIdPut: async (id: number, custRepairTicketReplaceRequest?: CustRepairTicketReplaceRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('custapiRepairTicketIdPut', 'id', id)
            const localVarPath = `/custapi/RepairTicket/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(custRepairTicketReplaceRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 状态切换
         * @param {number} id 维修单Id
         * @param {string} [transition] 事件类型
         * @param {Array<Operation>} [operation] 事件body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        custapiRepairTicketIdStatePatch: async (id: number, transition?: string, operation?: Array<Operation>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('custapiRepairTicketIdStatePatch', 'id', id)
            const localVarPath = `/custapi/RepairTicket/{id}/State`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (transition !== undefined) {
                localVarQueryParameter['transition'] = transition;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(operation, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 创建维修单（自营）
         * @param {CustRepairTicketCreateRequest} [custRepairTicketCreateRequest] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        custapiRepairTicketPost: async (custRepairTicketCreateRequest?: CustRepairTicketCreateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/custapi/RepairTicket`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(custRepairTicketCreateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RepairTicketApi - functional programming interface
 * @export
 */
export const RepairTicketApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = RepairTicketApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 维修单（自营）列表
         * @param {number} [pageNo] 页码
         * @param {number} [pageSize] 页大小
         * @param {string} [q] 筛选条件
         * @param {string} [orderBy] 排序条件
         * @param {string} [fields] 展示字段
         * @param {number} [isResourceAuthorization] 是否资源鉴权
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async custapiRepairTicketGet(pageNo?: number, pageSize?: number, q?: string, orderBy?: string, fields?: string, isResourceAuthorization?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CustRepairTicketPageReply>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.custapiRepairTicketGet(pageNo, pageSize, q, orderBy, fields, isResourceAuthorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 删除维修单（自营）
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async custapiRepairTicketIdDelete(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.custapiRepairTicketIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 维修单（自营）详情
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async custapiRepairTicketIdGet(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CustRepairTicketDetailReply>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.custapiRepairTicketIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 更新维修单（自营）
         * @param {number} id 
         * @param {CustRepairTicketReplaceRequest} [custRepairTicketReplaceRequest] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async custapiRepairTicketIdPut(id: number, custRepairTicketReplaceRequest?: CustRepairTicketReplaceRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.custapiRepairTicketIdPut(id, custRepairTicketReplaceRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 状态切换
         * @param {number} id 维修单Id
         * @param {string} [transition] 事件类型
         * @param {Array<Operation>} [operation] 事件body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async custapiRepairTicketIdStatePatch(id: number, transition?: string, operation?: Array<Operation>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.custapiRepairTicketIdStatePatch(id, transition, operation, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 创建维修单（自营）
         * @param {CustRepairTicketCreateRequest} [custRepairTicketCreateRequest] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async custapiRepairTicketPost(custRepairTicketCreateRequest?: CustRepairTicketCreateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CustRepairTicketCreateReply>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.custapiRepairTicketPost(custRepairTicketCreateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * RepairTicketApi - factory interface
 * @export
 */
export const RepairTicketApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = RepairTicketApiFp(configuration)
    return {
        /**
         * 
         * @summary 维修单（自营）列表
         * @param {number} [pageNo] 页码
         * @param {number} [pageSize] 页大小
         * @param {string} [q] 筛选条件
         * @param {string} [orderBy] 排序条件
         * @param {string} [fields] 展示字段
         * @param {number} [isResourceAuthorization] 是否资源鉴权
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        custapiRepairTicketGet(pageNo?: number, pageSize?: number, q?: string, orderBy?: string, fields?: string, isResourceAuthorization?: number, options?: any): AxiosPromise<Array<CustRepairTicketPageReply>> {
            return localVarFp.custapiRepairTicketGet(pageNo, pageSize, q, orderBy, fields, isResourceAuthorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 删除维修单（自营）
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        custapiRepairTicketIdDelete(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.custapiRepairTicketIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 维修单（自营）详情
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        custapiRepairTicketIdGet(id: number, options?: any): AxiosPromise<CustRepairTicketDetailReply> {
            return localVarFp.custapiRepairTicketIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 更新维修单（自营）
         * @param {number} id 
         * @param {CustRepairTicketReplaceRequest} [custRepairTicketReplaceRequest] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        custapiRepairTicketIdPut(id: number, custRepairTicketReplaceRequest?: CustRepairTicketReplaceRequest, options?: any): AxiosPromise<void> {
            return localVarFp.custapiRepairTicketIdPut(id, custRepairTicketReplaceRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 状态切换
         * @param {number} id 维修单Id
         * @param {string} [transition] 事件类型
         * @param {Array<Operation>} [operation] 事件body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        custapiRepairTicketIdStatePatch(id: number, transition?: string, operation?: Array<Operation>, options?: any): AxiosPromise<void> {
            return localVarFp.custapiRepairTicketIdStatePatch(id, transition, operation, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 创建维修单（自营）
         * @param {CustRepairTicketCreateRequest} [custRepairTicketCreateRequest] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        custapiRepairTicketPost(custRepairTicketCreateRequest?: CustRepairTicketCreateRequest, options?: any): AxiosPromise<CustRepairTicketCreateReply> {
            return localVarFp.custapiRepairTicketPost(custRepairTicketCreateRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * RepairTicketApi - object-oriented interface
 * @export
 * @class RepairTicketApi
 * @extends {BaseAPI}
 */
export class RepairTicketApi extends BaseAPI {
    /**
     * 
     * @summary 维修单（自营）列表
     * @param {number} [pageNo] 页码
     * @param {number} [pageSize] 页大小
     * @param {string} [q] 筛选条件
     * @param {string} [orderBy] 排序条件
     * @param {string} [fields] 展示字段
     * @param {number} [isResourceAuthorization] 是否资源鉴权
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof RepairTicketApi
     */
    public custapiRepairTicketGet(pageNo?: number, pageSize?: number, q?: string, orderBy?: string, fields?: string, isResourceAuthorization?: number, options?: AxiosRequestConfig) {
        return RepairTicketApiFp(this.configuration).custapiRepairTicketGet(pageNo, pageSize, q, orderBy, fields, isResourceAuthorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 删除维修单（自营）
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RepairTicketApi
     */
    public custapiRepairTicketIdDelete(id: number, options?: AxiosRequestConfig) {
        return RepairTicketApiFp(this.configuration).custapiRepairTicketIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 维修单（自营）详情
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RepairTicketApi
     */
    public custapiRepairTicketIdGet(id: number, options?: AxiosRequestConfig) {
        return RepairTicketApiFp(this.configuration).custapiRepairTicketIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 更新维修单（自营）
     * @param {number} id 
     * @param {CustRepairTicketReplaceRequest} [custRepairTicketReplaceRequest] 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof RepairTicketApi
     */
    public custapiRepairTicketIdPut(id: number, custRepairTicketReplaceRequest?: CustRepairTicketReplaceRequest, options?: AxiosRequestConfig) {
        return RepairTicketApiFp(this.configuration).custapiRepairTicketIdPut(id, custRepairTicketReplaceRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 状态切换
     * @param {number} id 维修单Id
     * @param {string} [transition] 事件类型
     * @param {Array<Operation>} [operation] 事件body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RepairTicketApi
     */
    public custapiRepairTicketIdStatePatch(id: number, transition?: string, operation?: Array<Operation>, options?: AxiosRequestConfig) {
        return RepairTicketApiFp(this.configuration).custapiRepairTicketIdStatePatch(id, transition, operation, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 创建维修单（自营）
     * @param {CustRepairTicketCreateRequest} [custRepairTicketCreateRequest] 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof RepairTicketApi
     */
    public custapiRepairTicketPost(custRepairTicketCreateRequest?: CustRepairTicketCreateRequest, options?: AxiosRequestConfig) {
        return RepairTicketApiFp(this.configuration).custapiRepairTicketPost(custRepairTicketCreateRequest, options).then((request) => request(this.axios, this.basePath));
    }
}
