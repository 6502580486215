import axios from 'axios'
import {
  Configuration,
  PayOrderApiFactory,
  FileApiFactory,
  RepairGoodsApiFactory,
  JstSaleOrderApiFactory,
  RepairTicketApiFactory,
  UserAddressApiFactory,
  BaiduAiApiFactory,
  SearchAutoApiFactory,
  RepairPartCostApiFactory,
  RepairGoodsCategoryApiFactory,
  InvoiceApplyApiFactory,
  InitialReviewOrderApiFactory,
  BannerConfigApiFactory
} from '@/api/bbcloud_maintenance_system'
import store from '@/store'
import { errorHandler } from '@/utils/errorHandler'

axios.defaults.withCredentials = true
axios.interceptors.request.use(async config => {
  if (process.env.NODE_ENV === 'development') {
    // 本地开发环境用header,可以让大部分接口能正常调试
    const headerInfo = store.getters.getHeaderInfo
    const { enCryptType, headInfo, productId } = headerInfo
    config.headers['Encrypt-Type'] = enCryptType
    config.headers['Client-Header-Info'] = headInfo
    config.headers['Product-Id'] = productId
  }
  return config
})

axios.interceptors.response.use(response => {
  const { config, headers = {} } = response
  if (config?.method === 'get' && headers['x-pageno']) {
    return {
      pageSize: Number(headers['x-pagesize']),
      pageNo: Number(headers['x-pageno']),
      recordCount: Number(headers['x-recordcount']),
      data: response.data
    }
  }
  return response.data
}, errorHandler)

const config = new Configuration({
  basePath: process.env.VUE_APP_API_BASE_URL,
  baseOptions: {
    timeout: 600000
  }
})

const api = {
  ...PayOrderApiFactory(config),
  ...FileApiFactory(config),
  ...RepairGoodsApiFactory(config),
  ...JstSaleOrderApiFactory(config),
  ...RepairTicketApiFactory(config),
  ...UserAddressApiFactory(config),
  ...BaiduAiApiFactory(config),
  ...SearchAutoApiFactory(config),
  ...RepairPartCostApiFactory(config),
  ...RepairGoodsCategoryApiFactory(config),
  ...InvoiceApplyApiFactory(config),
  ...InitialReviewOrderApiFactory(config),
  ...BannerConfigApiFactory(config)
}

export default api
