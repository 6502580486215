/* tslint:disable */
/* eslint-disable */
/**
 * 维修系统
 * 维修系统:v0.0.1版本
 *
 * The version of the OpenAPI document: v0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { JstSaleOrderSimpleDataByFinalSnOrSoIdReply } from '../models';
// @ts-ignore
import { JstSaleOrderSimpleDataByFinalSnReply } from '../models';
// @ts-ignore
import { JstSaleOrderSoIdOrderSimpleDataReply } from '../models';
/**
 * JstSaleOrderApi - axios parameter creator
 * @export
 */
export const JstSaleOrderApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 根据序列号查询聚水潭销售单简单信息
         * @param {string} finalSn 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        custapiJstSaleOrderSkuFinalSnFinalSnOrderSimpleDataGet: async (finalSn: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'finalSn' is not null or undefined
            assertParamExists('custapiJstSaleOrderSkuFinalSnFinalSnOrderSimpleDataGet', 'finalSn', finalSn)
            const localVarPath = `/Custapi/JstSaleOrder/SkuFinalSn/{finalSn}/OrderSimpleData`
                .replace(`{${"finalSn"}}`, encodeURIComponent(String(finalSn)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 根据序列号或订单号查询
         * @param {string} number 
         * @param {number} [repairGoodsId] 维修商品Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        custapiJstSaleOrderSoIdFinalSnNumberOrderSimpleDataGet: async (number: string, repairGoodsId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'number' is not null or undefined
            assertParamExists('custapiJstSaleOrderSoIdFinalSnNumberOrderSimpleDataGet', 'number', number)
            const localVarPath = `/Custapi/JstSaleOrder/SoIdFinalSn/{number}/OrderSimpleData`
                .replace(`{${"number"}}`, encodeURIComponent(String(number)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (repairGoodsId !== undefined) {
                localVarQueryParameter['RepairGoodsId'] = repairGoodsId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 根据订单号查询聚水潭销售单简单信息
         * @param {string} soId 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        custapiJstSaleOrderSoIdSoIdOrderSimpleDataGet: async (soId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'soId' is not null or undefined
            assertParamExists('custapiJstSaleOrderSoIdSoIdOrderSimpleDataGet', 'soId', soId)
            const localVarPath = `/Custapi/JstSaleOrder/SoId/{soId}/OrderSimpleData`
                .replace(`{${"soId"}}`, encodeURIComponent(String(soId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * JstSaleOrderApi - functional programming interface
 * @export
 */
export const JstSaleOrderApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = JstSaleOrderApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 根据序列号查询聚水潭销售单简单信息
         * @param {string} finalSn 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async custapiJstSaleOrderSkuFinalSnFinalSnOrderSimpleDataGet(finalSn: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<JstSaleOrderSimpleDataByFinalSnReply>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.custapiJstSaleOrderSkuFinalSnFinalSnOrderSimpleDataGet(finalSn, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 根据序列号或订单号查询
         * @param {string} number 
         * @param {number} [repairGoodsId] 维修商品Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async custapiJstSaleOrderSoIdFinalSnNumberOrderSimpleDataGet(number: string, repairGoodsId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<JstSaleOrderSimpleDataByFinalSnOrSoIdReply>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.custapiJstSaleOrderSoIdFinalSnNumberOrderSimpleDataGet(number, repairGoodsId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 根据订单号查询聚水潭销售单简单信息
         * @param {string} soId 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async custapiJstSaleOrderSoIdSoIdOrderSimpleDataGet(soId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<JstSaleOrderSoIdOrderSimpleDataReply>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.custapiJstSaleOrderSoIdSoIdOrderSimpleDataGet(soId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * JstSaleOrderApi - factory interface
 * @export
 */
export const JstSaleOrderApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = JstSaleOrderApiFp(configuration)
    return {
        /**
         * 
         * @summary 根据序列号查询聚水潭销售单简单信息
         * @param {string} finalSn 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        custapiJstSaleOrderSkuFinalSnFinalSnOrderSimpleDataGet(finalSn: string, options?: any): AxiosPromise<JstSaleOrderSimpleDataByFinalSnReply> {
            return localVarFp.custapiJstSaleOrderSkuFinalSnFinalSnOrderSimpleDataGet(finalSn, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 根据序列号或订单号查询
         * @param {string} number 
         * @param {number} [repairGoodsId] 维修商品Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        custapiJstSaleOrderSoIdFinalSnNumberOrderSimpleDataGet(number: string, repairGoodsId?: number, options?: any): AxiosPromise<JstSaleOrderSimpleDataByFinalSnOrSoIdReply> {
            return localVarFp.custapiJstSaleOrderSoIdFinalSnNumberOrderSimpleDataGet(number, repairGoodsId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 根据订单号查询聚水潭销售单简单信息
         * @param {string} soId 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        custapiJstSaleOrderSoIdSoIdOrderSimpleDataGet(soId: string, options?: any): AxiosPromise<Array<JstSaleOrderSoIdOrderSimpleDataReply>> {
            return localVarFp.custapiJstSaleOrderSoIdSoIdOrderSimpleDataGet(soId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * JstSaleOrderApi - object-oriented interface
 * @export
 * @class JstSaleOrderApi
 * @extends {BaseAPI}
 */
export class JstSaleOrderApi extends BaseAPI {
    /**
     * 
     * @summary 根据序列号查询聚水潭销售单简单信息
     * @param {string} finalSn 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof JstSaleOrderApi
     */
    public custapiJstSaleOrderSkuFinalSnFinalSnOrderSimpleDataGet(finalSn: string, options?: AxiosRequestConfig) {
        return JstSaleOrderApiFp(this.configuration).custapiJstSaleOrderSkuFinalSnFinalSnOrderSimpleDataGet(finalSn, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 根据序列号或订单号查询
     * @param {string} number 
     * @param {number} [repairGoodsId] 维修商品Id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JstSaleOrderApi
     */
    public custapiJstSaleOrderSoIdFinalSnNumberOrderSimpleDataGet(number: string, repairGoodsId?: number, options?: AxiosRequestConfig) {
        return JstSaleOrderApiFp(this.configuration).custapiJstSaleOrderSoIdFinalSnNumberOrderSimpleDataGet(number, repairGoodsId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 根据订单号查询聚水潭销售单简单信息
     * @param {string} soId 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof JstSaleOrderApi
     */
    public custapiJstSaleOrderSoIdSoIdOrderSimpleDataGet(soId: string, options?: AxiosRequestConfig) {
        return JstSaleOrderApiFp(this.configuration).custapiJstSaleOrderSoIdSoIdOrderSimpleDataGet(soId, options).then((request) => request(this.axios, this.basePath));
    }
}
